<template>
  <MoleculesConsumerModal :model-value="visible" :enable-browser-back-close="false" @update:model-value="closeModal">
    <div :class="$style.root">
      <template v-if="step === 'confirm'">
        <div :class="$style.header">
          <h3>羽毛布団の回収を依頼しますか？</h3>
        </div>
        <div :class="showButton ? $style.show_button : []">
          <div :class="$style.description"
            >処分が面倒な羽毛布団を、 商品をご購入いただいたお客様限定で、 無料回収します。</div
          >
          <div :class="$style.guidance">
            <div :class="$style.guidance_column">
              <div :class="$style.image_box">
                <AtomsConsumerImage src="/images/components/product/duvet_collection_guidance_1.png" :width="67" />
              </div>
              <div :class="$style.guidance_column_description">
                <div>
                  <p :class="$style.guidance_column_title">無料で回収</p>
                  <p :class="$style.guidance_column_text">送料・手数料は一切かかりません</p>
                </div>
              </div>
            </div>
            <div :class="$style.guidance_column">
              <div :class="$style.image_box">
                <AtomsConsumerImage src="/images/components/product/duvet_collection_guidance_2.png" :width="80" />
              </div>
              <div :class="$style.guidance_column_description">
                <div>
                  <p :class="$style.guidance_column_title">自宅で回収</p>
                  <p :class="$style.guidance_column_text">商品配達時や後日集荷で引き渡しができます。</p>
                </div>
              </div>
            </div>
            <div :class="$style.guidance_column">
              <div :class="$style.image_box">
                <AtomsConsumerImage src="/images/components/product/duvet_collection_guidance_3.png" :width="53" />
              </div>
              <div :class="$style.guidance_column_description">
                <div>
                  <p :class="$style.guidance_column_title">サスティナブル</p>
                  <p :class="$style.guidance_column_text">リサイクルするため、環境にもやさしいです。</p>
                </div>
              </div>
            </div>
          </div>
          <div :class="$style.precautions">
            <div :class="$style.precautions_title"> ❌ 回収ができないもの </div>
            <p>・ダウン率50%未満</p>
            <p>・品質表示がない</p>
            <p>・ダウン率が確認できない</p>
            <p>・濡れている</p>
            <p>・綿布団 / 羽毛枕</p>
            <p>・ポリエステル製のジャケット</p>
          </div>
        </div>
        <div v-if="showButton" :class="$style.btn_box">
          <AtomsConsumerButton
            variant="primary"
            block
            rounded
            :disabled="isBlock"
            :class="$style.done_btn"
            @click="() => (step = 'submit')"
          >
            回収をお願いする(無料)
          </AtomsConsumerButton>
          <AtomsConsumerButton variant="secondary" block rounded :disabled="isBlock" @click="notUse">
            今回は利用しない
          </AtomsConsumerButton>
        </div>
        <div v-else>
          <div :class="$style.no_show_button">※商品をカートに追加する際に、回収希望をお伺いいたします。</div>
          <AtomsConsumerButton
            variant="primary"
            block
            rounded
            :disabled="isBlock"
            @click="() => navigateTo('/features/futonkaisyu-program')"
          >
            詳しくはこちら
          </AtomsConsumerButton>
        </div>
      </template>
      <template v-else-if="step === 'submit'">
        <div :class="$style.header">
          <h3>回収方法を選択してください</h3>
        </div>
        <div>
          <AtomsConsumerRadioButton
            :checked="duvetCollectionType"
            value="collected_upon_delivery"
            name="duvetCollectionType"
            :class="$style.duvet_collection_type"
            @change="(v) => (duvetCollectionType = v)"
          >
            <div :class="$style.duvet_collection_type_inner">
              <div :class="$style.image_box">
                <AtomsConsumerImage src="/images/components/product/collected_upon_delivery.png" :width="80" />
              </div>
              <div :class="$style.duvet_collection_type_title">お届け時に回収</div>
              <div :class="$style.duvet_collection_type_text_1">商品受取の際、業者へお渡しください｡</div>
            </div>
          </AtomsConsumerRadioButton>
          <AtomsConsumerRadioButton
            :checked="duvetCollectionType"
            value="pick_up_later"
            name="duvetCollectionType"
            :class="$style.duvet_collection_type"
            @change="(v) => (duvetCollectionType = v)"
          >
            <div :class="$style.duvet_collection_type_inner">
              <div :class="$style.image_box">
                <AtomsConsumerImage src="/images/components/product/pick_up_later.png" :width="80" />
              </div>
              <div :class="$style.duvet_collection_type_title">集荷を依頼</div>
              <div :class="$style.duvet_collection_type_text_1"
                >ご自身で、弊社指定の宅配業者に集荷を依頼いただき、布団をお送りください｡</div
              >
              <div :class="$style.duvet_collection_type_text_2">※ご注文後メールにて詳細をお送り致します｡</div>
            </div>
          </AtomsConsumerRadioButton>
        </div>
        <div :class="$style.close_btn_box">
          <AtomsConsumerButton variant="primary" block rounded :disabled="isBlock" @click="use">
            確定する
          </AtomsConsumerButton>
        </div>
      </template>
    </div>
  </MoleculesConsumerModal>
</template>

<script setup lang="ts">
import type { DuvetCollectionType } from "@tential/ec-gql-schema/models/order"
import { useDuvetCollection } from "~/composables/useDuvetCollection"

const { getDuvetCollectionType, setDuvetCollectionType } = useDuvetCollection()

type Props = {
  visible: boolean
  showButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  visible: false,
  showButton: true,
})

type Step = "confirm" | "submit"
const step = ref<Step>("confirm")
const duvetCollectionType = ref<DuvetCollectionType>("collected_upon_delivery")
const isBlock = ref(false)

const emit = defineEmits<{
  (e: "cancel"): void
  (e: "done"): void
}>()

const closeModal = () => {
  emit("cancel")
}

const notUse = () => {
  isBlock.value = true
  setDuvetCollectionType("none")
  emit("done")
}

const use = () => {
  isBlock.value = true
  setDuvetCollectionType(duvetCollectionType.value)
  emit("done")
}

onMounted(() => {
  if (getDuvetCollectionType() !== "pick_up_later") {
    duvetCollectionType.value = "collected_upon_delivery"
  } else {
    duvetCollectionType.value = "pick_up_later"
  }
})

watch(
  () => props.visible,
  (v) => {
    if (!v) {
      isBlock.value = false
      step.value = "confirm"
    }
  },
)
</script>

<style scoped module lang="scss">
.root {
  max-width: 500px;

  .header {
    font-size: $font-size-16;
    color: $primary;
    margin-top: 30px;
    padding-bottom: 12px;
    border-bottom: 1px solid $primary-20;
    text-align: center;
    margin-bottom: 15px;
  }
  .description {
    font-size: $font-size-15;
    color: $font-color-primary;
    padding-bottom: 10px;
  }
  .guidance {
    background-color: $primary-10;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 15px;

    .guidance_column {
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: $white;
      border-radius: 8px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      div {
        width: 100%;
      }

      .guidance_column_description {
        display: flex;
        justify-content: left;
        align-items: center;
        padding-right: 10px;

        .guidance_column_title {
          font-size: $font-size-14;
          color: $font-color-primary;
          font-weight: bold;
          padding-bottom: 5px;
        }

        .guidance_column_text {
          font-size: $font-size-12;
          color: $font-color-primary;
        }
      }
    }
  }

  .image_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 84px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: $white;
  }

  .precautions {
    background-color: #ffdada;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;

    .precautions_title {
      font-size: $font-size-15;
      color: $black-100;
      font-weight: bold;
    }
    p {
      font-size: $font-size-14;
      color: $font-color-primary;
    }
  }

  .btn_box {
    width: 100%;
    display: flex;
    gap: 10px;
    background-color: $white;

    @media screen and (max-width: 768px) {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 1rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;

      .done_btn {
        margin-bottom: 10px;
      }
    }
  }

  .show_button {
    @media screen and (max-width: 768px) {
      margin-bottom: 120px;
    }
  }

  .no_show_button {
    font-size: $font-size-14;
    color: $primary-100;
    padding-bottom: 15px;
  }

  .duvet_collection_type {
    background-color: $primary-10;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 15px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .duvet_collection_type_inner {
      width: 100%;

      .duvet_collection_type_title {
        font-size: $font-size-16;
        color: $primary-100;
        font-weight: bold;
      }

      .duvet_collection_type_text_1 {
        font-size: $font-size-14;
        color: $font-color-primary;
      }

      .duvet_collection_type_text_2 {
        font-size: $font-size-12;
        color: $black-60;
      }
    }
  }
}
</style>
